import React from "react"
import styled from "styled-components"

const ResumeHeadingStyles = styled.div`
  @media (min-width: 1050px) {
    margin-left: -50px;
  }
`

const HeadingIconWrapper = styled.div`
    width: 2.5rem;
    margin-right: 1rem;

`

const HeadingH2 = styled.h2`

    font-size: 2rem;

`

const ResumeSectionHeadingWithIcon = ({ content }) => {
  const { heading, headingIcon, headingIconAltText } = content
  return (
    <ResumeHeadingStyles style={{ display: `flex`, marginBottom: `1rem`, marginTop: '6rem' }}>
      <HeadingIconWrapper>
        <img src={headingIcon} alt={headingIconAltText} />
      </HeadingIconWrapper>
      <div>
        <HeadingH2 style={{paddingTop: '0.2rem'}}>{heading}</HeadingH2>
      </div>
    </ResumeHeadingStyles>
  )
}

export default ResumeSectionHeadingWithIcon
