import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import ResumeSectionSubheading from "./ResumeSectionSubheading"
import Sample from "./Sample"

const SampleListAndLinkStyles = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const SampleListStyles = styled.div`
display: flex;

  @media (min-width: 768px) {
    flex-wrap: wrap;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const OutputSamplesLinkStyles = styled(Link)`
  border: 2px solid #1eaef1;
  border-radius: 4px;
  color: #1eaef1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding .2rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
  height: 80px;


  :hover {
    background-color: #1eaef1;
    color: white;
  }

  @media (min-width: 768px) {
    height: 100%;
  }


`

const Operation = ({ content, operationlist }) => {
  const { frontmatter } = content.edges[0].node
  const { subheading } = frontmatter
  const operations = operationlist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      <ResumeSectionSubheading content={subheading} />
      <SampleListAndLinkStyles>
        <SampleListStyles>
          {operations.map((data, index) => {
            const sampleContent = data.node.frontmatter
            return <Sample key={`sample_${index}`} content={sampleContent} />
          })}
        </SampleListStyles>
        <div>
          <OutputSamplesLinkStyles to="/portfolio">
            <div>&rarr;</div>
            <div>Output</div>
            <div>Samples</div>
          </OutputSamplesLinkStyles>
        </div>
      </SampleListAndLinkStyles>
    </ResumeSection>
  )
}

export default Operation
