import React from "react"
import styled from "styled-components"

const HeadingH2 = styled.h2`
  @media (min-width: 500px) {
    font-size: 2rem;
  }
`

const ResumeSectionHeading = ({ content }) => {
  return (
    <div style={{ marginBottom: `.5rem` }}>
      <HeadingH2>{content}</HeadingH2>
    </div>
  )
}

export default ResumeSectionHeading
