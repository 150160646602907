import React from "react"
import styled from "styled-components"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeading from "./ResumeSectionHeading"
import ResumeSectionSubheading from "./ResumeSectionSubheading"
import ProductInfoItem, { ProductInfoFirstItem } from "./ProductInfoItem"
import Skill from "./Skill"
import Toggle from "./Toggle"
import MyersBriggs from "./MyersBriggs"
import PersonalityList from "./PersonalityList"

const TopSectionFlex = styled.div`
  
  img {
    display: block;
    max-width: 168px;
    margin-left: auto;
    margin-right: auto;
  }

  margin-top: 2rem;
  margin-bottom: 5rem;

  li {
    margin-bottom: .8rem;
  }
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 748px) {

    margin-top: 4rem;
    flex-wrap: nowrap;

    img {
      max-width: 336px;
    }
    
    & > div:nth-child(1) {
      margin-right: 5rem;
    }
  }
`

const ToggleSectionFlex = styled.div`
  @media (min-width: 748px) {
    display: flex;
    justify-content: space-between;
  }
`

const SubheadingFix = styled.div`
  @media (max-width: 1050px) {
    margin-left: -3.5rem;
  }
`

const ProductPackageWrapper = styled.div`
  width: 16rem;
  margin-left: 4rem;
  margin-top: 1rem;

  @media (max-width: 748px) {
    margin: 3rem auto;

    img {
      width: 200px;
    }

  }
`

const ForUseWithSection = styled.div`

flex-wrap: wrap;

  @media (min-width: 748px) {
    flex-wrap: none;
  }
`

const Photo = styled.div`

  @media (max-width: 748px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`

const WhatsInTheBox = ({ content, personalitylist }) => {
  const {
    heading,
    subheading,
    portrait,
    portraitAltText,
    productinfo,
    forusewithHeading,
    forusewith,
    productPackage,
    productPackageAltText,
    myersBriggsHeading,
    myersbriggs,
  } = content.edges[0].node.frontmatter
  const personalitylists = personalitylist.edges

  return (
    <ResumeSection>
      <div style={{marginTop: '5rem'}}>
        <ResumeSectionHeading content={heading} />
        <SubheadingFix>
          <div>
            <ResumeSectionSubheading content={subheading} />
          </div>
        </SubheadingFix>
      </div>
      <TopSectionFlex>
        <Photo>
          <div style={{ marginBottom: `.5rem`}}>
           <img src={portrait} alt={portraitAltText} />
          </div>
        </Photo>
        <div style={{ flex: `100%`, marginBottom: `3rem` }}>
          <ul style={{ listStyleType: `none`, marginLeft: 0 }}>
            {productinfo.map((item, index) => {
              return index === 0 ? (
                <ProductInfoFirstItem
                  key={`productInfo_item_${index}`}
                  content={item}
                />
              ) : (
                <ProductInfoItem
                  key={`productInfo_item_${index}`}
                  content={item}
                />
              )
            })}
          </ul>
        </div>
        <ForUseWithSection>
        <div style={{ marginBottom: `.2rem` }}>
          <h3 style={{ marginBottom: `.5rem` }}>{forusewithHeading}</h3>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              listStyleType: `none`,
              marginLeft: 0,
            }}
          >
            {forusewith.map((data, index) => {
              return <Skill key={`skill_${index}`} skill={data} />
            })}
          </ul>
        </div>
        </ForUseWithSection>
      </TopSectionFlex>
      <Toggle>
        <ToggleSectionFlex>
          <ProductPackageWrapper>
            <img src={productPackage} alt={productPackageAltText} />
          </ProductPackageWrapper>
          <div>
            <MyersBriggs heading={myersBriggsHeading} content={myersbriggs} />
            {personalitylists.map((data, index) => {
              const { frontmatter } = data.node
              return (
                <PersonalityList
                  key={`personality_list_${index}`}
                  content={frontmatter}
                />
              )
            })}
          </div>
        </ToggleSectionFlex>
      </Toggle>
    </ResumeSection>
  )
}

export default WhatsInTheBox
