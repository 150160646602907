import React from "react"
import styled from "styled-components"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"

const OrderingInformationContentStyles = styled.div`
  
  margin-top: 3rem;

@media (min-width: 768px) {
    display: flex;
  }
`

const PhoneAndEmail = styled.div`
  flex: none;
  margin-bottom: 0;
  margin-right: 10rem;

  li {
    margin-bottom: .4rem;
  }

  img {
    border-radius: 0;
  }

  @media (max-width: 768px) {
    width: 50%;
    margin: auto;
  }
`

const MiddleColumnContent = styled.div`
  flex: 3;
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;
    color: #1eaef1;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 10rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
`

const QRCode = styled.div`
 
  max-width: 100px;

  @media (max-width: 768px) {
    max-width: 200px;
    flex: 1;
    margin: 3rem auto;
  }
`

const OrderingInformation = ({ content }) => {
  const { frontmatter, html } = content.edges[0].node
  const { contactinfo, qrCode, qrCodeAltText } = frontmatter
  return (
    <section>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      <OrderingInformationContentStyles>
        {/*<PhoneAndEmail>
          <ul style={{ listStyleType: `none` }}>
            {contactinfo.map((data, index) => {
              const { icon, iconAltText, info } = data
              return (
                <li key={`contactinfo_item_${index}`} style={{marginBottom: '1rem'}}>
                  <div style={{ alignItems: `center`, display: `flex` }}>
                    <div style={{ marginRight: `1rem`, width: `1.2rem` }}>
                      <img src={icon} alt={iconAltText} />
                    </div>
                    <div>{info}</div>
                  </div>
                </li>
              )
            })}
          </ul>
          </PhoneAndEmail>*/}
        <MiddleColumnContent dangerouslySetInnerHTML={{ __html: html }} />
        <QRCode>
          <img src={qrCode} alt={qrCodeAltText} />
        </QRCode>
      </OrderingInformationContentStyles>
    </section>
  )
}

export default OrderingInformation
