import React from "react"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import DocumentationListItem from "./DocumentationListItem"
import Toggle from "./Toggle"

const Documentation = ({ content, documentationlist }) => {
  const { frontmatter } = content.edges[0].node
  const documentationList = documentationlist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      {documentationList.map((data, index) => {
        const { showMore } = data.node.frontmatter
        return (
          !showMore && (
            <DocumentationListItem
              key={`documentation_list_item_${index}`}
              content={data}
            />
          )
        )
      })}
      <Toggle>
        {documentationList.map((data, index) => {
          const { showMore } = data.node.frontmatter
          return (
            showMore && (
              <DocumentationListItem
                key={`showmore_documentation_list_item_${index}`}
                content={data}
              />
            )
          )
        })}
      </Toggle>
    </ResumeSection>
  )
}

export default Documentation
