import React from "react"
import styled from "styled-components"
import "./review.css"

const ReviewStyles = styled.div`
  break-inside: avoid;
  font-size: 0.857rem;
  margin-bottom: 1.5rem;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
`

const ReviewBubble = styled.div`
  background-color: #eee;
  border-radius: 20px 20px 20px 0;
  padding: 2rem;

  p {
    line-height: 1.4rem;
    margin-bottom: 1.5rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const ReviewListItem = styled.li`
  margin-right: 0.5rem;
`

const Review = ({ content }) => {
  const { frontmatter, html } = content.node
  return (
    <ReviewStyles>
      <div className="review-bubble-wrapper">
        <ReviewBubble dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div style={{ marginLeft: `1.5rem` }}>
        <ul
          style={{
            listStyleType: `none`,
            marginLeft: `0`,
            display: `flex`,
          }}
        >
          {frontmatter.authorTitle && (
            <ReviewListItem>{frontmatter.authorTitle}</ReviewListItem>
          )}
          <ReviewListItem>{frontmatter.company}</ReviewListItem>
          <ReviewListItem>{frontmatter.year}</ReviewListItem>
        </ul>
      </div>
    </ReviewStyles>
  )
}

export default Review
