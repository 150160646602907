import React from "react"
import styled from "styled-components"

const ExperienceStyles = styled.section`
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    display: flex;
    margin-bottom: 2rem;
  }
`

const ThumbnailStyles = styled.div`
  flex: 100%;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
  max-width: 110px;

  @media (min-width: 768px) {

  }
`

const ExperienceListWrapper = styled.div`
  ul li {
    margin-bottom: 0.3rem;
  }
`

const Experience = ({ content }) => {
  const { frontmatter } = content.node
  return (
    <ExperienceStyles>
      <ThumbnailStyles>
        <img src={frontmatter.thumbnail} alt={frontmatter.thumbnailAltText} />
      </ThumbnailStyles>
      <div>
        <div style={{ marginBottom: `.5rem` }}>
          <h3>{frontmatter.title}</h3>
        </div>
        <ExperienceListWrapper>
          <ul style={{ listStyleType: `none`, marginLeft: 0 }}>
            <li>{frontmatter.company}</li>
            <li>{frontmatter.duration}</li>
          </ul>
        </ExperienceListWrapper>
        <p dangerouslySetInnerHTML={{ __html: content.node.html }} />
      </div>
    </ExperienceStyles>
  )
}

export default Experience
