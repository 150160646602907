import React from "react"
import styled from "styled-components"

const ItemStyles = styled.div`
  margin-bottom: 3rem;

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`

const ThumbnailWrapper = styled.div`
  flex: none;

  @media (min-width: 768px) {
    margin-right: 2.5rem;
  }
`

const DocumentationLinkA = styled.a`
  display: inline-block;
  max-width: 184px;
  margin
`

const DocumentationListItem = ({ content }) => {
  const {
    thumbnail,
    thumbnailAltText,
    link,
    title,
    year,
    locationEvent,
    additionalLocationEvent,
  } = content.node.frontmatter
  return (
    <ItemStyles>
      <ThumbnailWrapper>
        <DocumentationLinkA
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={thumbnail} alt={thumbnailAltText} />
        </DocumentationLinkA>
      </ThumbnailWrapper>
      <div>
        <ul style={{ listStyleType: `none` }}>
          <li style={{ marginBottom: `.5rem` }}>
            <strong>{title}</strong>
          </li>
          <li style={{ marginBottom: `.3rem` }}>{year}</li>
          {locationEvent && (
            <li style={{ marginBottom: `.3rem` }}>{locationEvent}</li>
          )}
          {additionalLocationEvent && (
            <li style={{ marginBottom: `.3rem` }}>{additionalLocationEvent}</li>
          )}
        </ul>
      </div>
    </ItemStyles>
  )
}

export default DocumentationListItem
