import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/Nav"
import Resume from "../components/Resume"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Product" />
      <Nav page="resume" />
      <Resume data={data} />
    </Layout>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  {
    whatsinthebox: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "whatsinthebox" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            subheading
            portrait
            portraitAltText
            productinfo {
              infoheading
              infocontent
            }
            forusewithHeading
            forusewith
            productPackage
            productPackageAltText
            myersBriggsHeading
            myersbriggs {
              category1
              category2
              active
            }
          }
        }
      }
    }
    fieldtesting: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "fieldtesting" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    operation: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "operation" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            subheading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    productreviews: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "productreviews" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            subheading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    performance: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "performance" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            subheading
            legendImage
            legendImageAltText
            legendLabels
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    documentation: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "documentation" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    technicalspecifications: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "technicalspecifications" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    awards: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "awards" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            headingIcon
            headingIconAltText
          }
        }
      }
    }
    orderinginformation: allMarkdownRemark(
      filter: { frontmatter: { whichPage: { eq: "orderinginformation" } } }
    ) {
      edges {
        node {
          frontmatter {
            heading
            headingIcon
            headingIconAltText
            contactinfo {
              icon
              iconAltText
              info
            }
            qrCode
            qrCodeAltText
          }
          html
        }
      }
    }
    personalitylist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "personalitylists" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            title
            listitems {
              content
              active
            }
          }
        }
      }
    }
    fieldtestinglist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "fieldtesting" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          html
          frontmatter {
            thumbnail
            thumbnailAltText
            title
            company
            duration
            showMore
          }
        }
      }
    }
    operationlist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "operations" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            slug
            thumbnail
            thumbnailAltText
          }
        }
      }
    }
    reviewlist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "reviews" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          html
          frontmatter {
            authorTitle
            company
            year
            showMore
          }
        }
      }
    }
    performancelist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "performance" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            showMore
            title
            performanceitems {
              item
              value
            }
          }
        }
      }
    }
    documentationlist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "documentation" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            showMore
            thumbnail
            thumbnailAltText
            link
            title
            year
            locationEvent
            additionalLocationEvent
          }
        }
      }
    }
    techspecslist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "techspecs" } } }
    ) {
      edges {
        node {
          frontmatter {
            thumbnail
            thumbnailAltText
            title
            achievement
            field_of_study
            school
            year
            showMore
          }
        }
      }
    }
    awardslist: allMarkdownRemark(
      filter: { frontmatter: { whichCollection: { eq: "awards" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            thumbnail
            thumbnailAltText
            title
            locationEvent
            year
            category
            additionalInfo
          }
        }
      }
    }
  }
`
