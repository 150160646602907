import React from "react"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import TechSpec from "./TechSpec"
import Toggle from "./Toggle"

const TechnicalSpecifications = ({ content, techspecslist }) => {
  const { frontmatter } = content.edges[0].node
  const techspecsList = techspecslist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      <div>
        {techspecsList.map((data, index) => {
          const showMore = data.node.frontmatter.showMore
          return !showMore && <TechSpec key={`techspec_${index}`} data={data} />
        })}
        <Toggle>
          {techspecsList.map((data, index) => {
            const showMore = data.node.frontmatter.showMore
            return (
              showMore && <TechSpec key={`techspec_${index}`} data={data} />
            )
          })}
        </Toggle>
      </div>
    </ResumeSection>
  )
}

export default TechnicalSpecifications
