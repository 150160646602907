import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import PerformanceListItem from "./PerformanceListItem"

const HeadingH3 = styled.h3`

  @media (min-width: 500px) {
    font-size: 1rem;
  }
`

const PerformanceListSection = ({ heading, performanceitems }) => {
  const [ref, inView] = useInView({
    rootMargin: "-200px 0px",
    triggerOnce: true,
  })
  return (
    <div style={{ marginBottom: `1rem`, marginTop: '2.5rem' }} ref={ref}>
      <div style={{ marginBottom: `.5rem`, width: '12rem', textAlign: 'right' }}>
        <HeadingH3>{heading}</HeadingH3>
      </div>
      <div>
        <ul style={{ listStyleType: `none` }}>
          {performanceitems.map((data, index) => {
            return (
              <PerformanceListItem
                key={`performance_list_item_${index}`}
                data={data}
                isInView={inView}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default PerformanceListSection
