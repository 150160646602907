import React from "react"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import ResumeSectionSubheading from "./ResumeSectionSubheading"
import Review from "./Review"
import Toggle from "./Toggle"
// import FadeOutGradient from "../images/gradient-932x300.svg"

const ProductReviews = ({ content, reviewlist }) => {
  const { frontmatter } = content.edges[0].node
  const { subheading } = frontmatter
  const reviewList = reviewlist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      <ResumeSectionSubheading content={subheading} />
      <div style={{ columnCount: 3, columnWidth: `17rem`, columnGap: '2rem'}}>
        {reviewList.map((data, index) => {
          const showMore = data.node.frontmatter.showMore
          return !showMore && <Review key={`review_${index}`} content={data} />
        })}
        <Toggle>
          {reviewList.map((data, index) => {
            const showMore = data.node.frontmatter.showMore
            return (
              showMore && (
                <Review key={`show_more_review_${index}`} content={data} />
              )
            )
          })}
        </Toggle>
      </div>
      {/* <div style={{ position: `relative` }}>
        <div
          style={{
            position: `absolute`,
            bottom: 0,
            left: 0,
            width: `100%`,
          }}
        >
          <img src={FadeOutGradient} alt="" aria-hidden="true" />
        </div>
      </div> */}
    </ResumeSection>
  )
}

export default ProductReviews
