import React from "react"

const PersonalityListItem = ({ data }) => {
  return data.active ? (
    <li
      style={{
        backgroundColor: `#1eaef1`,
        border: `1px solid #1eaef1`,
        borderRadius: `4px`,
        color: `#fff`,
        marginBottom: `.5rem`,
        padding: `0.8rem 1.4rem`,
        textAlign: `center`,
      }}
    >
      {data.content}
    </li>
  ) : (
    <li
      style={{
        border: `1px solid #eee`,
        borderRadius: `4px`,
        marginBottom: `.5rem`,
        padding: `0.8rem 1.4rem`,
        textAlign: `center`,
      }}
    >
      {data.content}
    </li>
  )
}

export default PersonalityListItem
