import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const SampleLinkStyles = styled(Link)`
  display: block;
  max-width: 254px;
  margin-right: 0.4rem;

  @media (min-width: 548px) {
    
  }
`

const Sample = ({ content }) => (
  <SampleLinkStyles to={`/portfolio/${content.slug}`}>
    <img src={content.thumbnail} alt={content.thumbnailAltText} />
  </SampleLinkStyles>
)

export default Sample
