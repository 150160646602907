import React from "react"
import styled from "styled-components"

const AwardStyles = styled.div`
  margin-bottom: 2.5rem;
  display: flex;
  float: left;
  width: 80%;
  margin-right: 20%;

  ul {
    width: 16rem;
  }

  @media (min-width: 880px){
    ul{
      width: 20rem;
    }
  }

  @media (min-width: 768px) {
    width: 40%;
    margin-right: 10%;
  }
`

const AwardThumb = styled.div`
  margin-bottom: 0.5rem;
  width: 37px;

  @media (min-width: 768px) {
    
  }
`

const Award = ({ data }) => {
  const content = data.node.frontmatter
  return (
    <AwardStyles>
      <AwardThumb style={{marginRight: '3rem' }}>
        <img src={content.thumbnail} alt={content.thumbnailAltText} />
      </AwardThumb>
      <div style={{ height: '9rem'}}>
        <h3 style={{ marginBottom: `.5rem` }}>{content.title}</h3>
        <ul style={{ listStyleType: `none` }}>
          <li style={{ marginBottom: `.3rem` }}>{content.locationEvent}</li>
          <li style={{ marginBottom: `.3rem` }}>{content.year}</li>
          {content.category && (
            <li style={{ marginBottom: `.3rem` }}>{content.category}</li>
          )}
          <li>{content.additionalInfo}</li>
        </ul>
      </div>
    </AwardStyles>
  )
}

export default Award
