import React from "react"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import ResumeSectionSubheading from "./ResumeSectionSubheading"
import PerformanceLegend from "./PerformanceLegend"
import PerformanceListSection from "./PerformanceListSection"
import Toggle from "./Toggle"

const Performance = ({ content, performancelist }) => {
  const { frontmatter } = content.edges[0].node
  const { subheading } = frontmatter
  const performanceList = performancelist.edges

  return (
    <ResumeSection>
      <div>
        <ResumeSectionHeadingWithIcon content={frontmatter} />
        <ResumeSectionSubheading content={subheading} />
      </div>
      <div>
        <PerformanceLegend content={frontmatter} />
        <div>
          {performanceList.map((data, index) => {
            const { showMore, title, performanceitems } = data.node.frontmatter
            return (
              !showMore && (
                <PerformanceListSection
                  key={`performance_list_section_${index}`}
                  heading={title}
                  performanceitems={performanceitems}
                />
              )
            )
          })}
        </div>
      </div>
      <Toggle>
        {performanceList.map((data, index) => {
          const { showMore, title, performanceitems } = data.node.frontmatter
          return (
            showMore && (
              <PerformanceListSection
                key={`performance_list_section_${index}`}
                heading={title}
                performanceitems={performanceitems}
              />
            )
          )
        })}
      </Toggle>
    </ResumeSection>
  )
}

export default Performance
