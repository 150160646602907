import React from "react"
import styled from "styled-components"

const Category = styled.div`
  border: 1px solid #eee;
  border-radius: 25px;
  margin-bottom: 0.3rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  text-align: center;
`

const ActiveCategory = styled.div`
  background-color: #1eaef1;
  border: 1px solid #1eaef1;
  border-radius: 25px;
  color: #fff;
  margin-bottom: 0.3rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  text-align: center;
`

const MyersBriggsCategory = ({ data }) => {
  return data.active === "category1" ? (
    <li>
      <ActiveCategory>{data.category1}</ActiveCategory>
      <Category>{data.category2}</Category>
    </li>
  ) : (
    <li>
      <Category>{data.category1}</Category>
      <ActiveCategory>{data.category2}</ActiveCategory>
    </li>
  )
}

export default MyersBriggsCategory
