import React from "react"
import styled from "styled-components"

const Subheading = styled.div`
@media (max-width: 1050px) {
    margin-left: 3.5rem;
  }
`
const ResumeSectionSubheading = ({ content }) => (
  <Subheading>
    <div style={{ marginBottom: `.5rem` }}>
      <p>{content}</p>
    </div>
  </Subheading>
)

export default ResumeSectionSubheading
