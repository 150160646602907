import React from "react"
import MyersBriggsCategory from "./MyersBriggsCategory"

const MyersBriggs = ({ heading, content }) => {
  return (
    <div style={{ marginBottom: `1rem` }}>
      <div style={{ marginBottom: `.5rem` }}>
        <h3>{heading}</h3>
      </div>
      <ul
        style={{
          display: `grid`,
          gridColumnGap: `0.3rem`,
          gridTemplateColumns: `repeat(4, 3rem)`,
          listStyleType: `none`,
          marginLeft: 0,
        }}
      >
        {content.map((data, index) => {
          return (
            <MyersBriggsCategory
              key={`myersbriggs_category_${index}`}
              data={data}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default MyersBriggs
