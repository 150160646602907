import React from "react"
import ResumeSection from "./ResumeSection"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"
import Toggle from "./Toggle"
import Award from "./Award"

const Awards = ({ content, awardslist }) => {
  const { frontmatter } = content.edges[0].node
  const awardsList = awardslist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      <Toggle>
        {awardsList.map((data, index) => {
          return <Award key={`award_${index}`} data={data} />
        })}
        <div style={{clear: 'both'}}>
        </div>
      </Toggle>
    </ResumeSection>
  )
}

export default Awards
