import React from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

const ListItemStyles = styled.li`
  margin-bottom: 0.5rem;

  p {
    text-align: right;
  }

  .grid-div {
    height: 1.2rem;
    display: grid;
    grid-template-columns: 13rem auto;
  }

  :last-child {
    margin-bottom: 0;
  }
`

const PerformanceListItem = ({ data, isInView }) => {
  const animationProps = useSpring({
    width: isInView ? `${data.value}%` : `0%`,
  })
  return (
    <ListItemStyles>
      <div class="grid-div">
        <div style={{width: '13rem', paddingRight: '1rem'}}>
          <p>{data.item}</p>
        </div>
        <div style={{}}>
          <animated.div
            style={{
              backgroundColor: `#1eaef1`,
              borderRadius: `2px`,
              color: `#1eaef1`,
              height: `1.4rem`,
              marginTop: '0.25rem',
              ...animationProps,
            }}
          />
        </div>
      </div>
    </ListItemStyles>
  )
}

export default PerformanceListItem
