import React from "react"
import styled from "styled-components"

const TechSpecStyles = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    display: flex;
  }
`

const TechSpecThumb = styled.div`
  max-width: 110px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (min-width: 768px) {
    margin-right: 2.5rem;
  }
`

const TechSpec = ({ data }) => {
  const content = data.node.frontmatter
  return (
    <TechSpecStyles>
      <TechSpecThumb>
        <img src={content.thumbnail} alt={content.thumbnailAltText} />
      </TechSpecThumb>
      <div style={{height: '9rem'}}>
        <h3 style={{ fontSize: `1rem`, marginBottom: `.5rem` }}>
          {content.title}
        </h3>
        <ul style={{ listStyleType: `none` }}>
          {content.achievement && (
            <li style={{ marginBottom: `.3rem` }}>{content.achievement}</li>
          )}
          {content.field_of_study && (
            <li style={{ marginBottom: `.3rem` }}>{content.field_of_study}</li>
          )}
          {content.school && (
            <li style={{ marginBottom: `.3rem` }}>{content.school}</li>
          )}
          <li>{content.year}</li>
        </ul>
      </div>
    </TechSpecStyles>
  )
}

export default TechSpec
