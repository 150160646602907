import React from "react"

const ProductInfoItem = ({ content }) => {
  const { infoheading } = content
  const { infocontent } = content
  return (
    <li>
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `1fr 1fr`,
        }}
      >
        <div>
          <strong>{infoheading}</strong>
        </div>
        <div>{infocontent}</div>
      </div>
    </li>
  )
}

const ProductInfoFirstItem = ({ content }) => {
  const { infoheading, infocontent } = content
  return (
    <li style={{ marginBottom: `1.4rem` }}>
      {/* <li> */}
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `1fr 1fr`,
        }}
      >
        <div>
          <strong>{infoheading}</strong>
        </div>
        <div>{infocontent}</div>
      </div>
    </li>
  )
}

export default ProductInfoItem
export { ProductInfoFirstItem }
