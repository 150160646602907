import React from "react"
import WhatsInTheBox from "./WhatsInTheBox"
import FieldTesting from "./FieldTesting"
import Operation from "./Operation"
import ProductReviews from "./ProductReviews"
import Performance from "./Performance"
import Documentation from "./Documentation"
import TechnicalSpecifications from "./TechnicalSpecifications"
import Awards from "./Awards"
import OrderingInformation from "./OrderingInformation"

const Resume = ({ data }) => {
  const {
    whatsinthebox,
    personalitylist,
    fieldtesting,
    fieldtestinglist,
    operation,
    operationlist,
    productreviews,
    reviewlist,
    performance,
    performancelist,
    documentation,
    documentationlist,
    technicalspecifications,
    techspecslist,
    awards,
    awardslist,
    orderinginformation,
  } = data
  return (
    <div>
      <WhatsInTheBox
        content={whatsinthebox}
        personalitylist={personalitylist}
      />
      <FieldTesting
        content={fieldtesting}
        fieldtestinglist={fieldtestinglist}
      />
      <Operation content={operation} operationlist={operationlist} />
      <ProductReviews content={productreviews} reviewlist={reviewlist} />
      <Performance content={performance} performancelist={performancelist} />
      <Documentation
        content={documentation}
        documentationlist={documentationlist}
      />
      <TechnicalSpecifications
        content={technicalspecifications}
        techspecslist={techspecslist}
      />
      <Awards content={awards} awardslist={awardslist} />
      <OrderingInformation content={orderinginformation} />
    </div>
  )
}

export default Resume
