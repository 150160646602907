import React from "react"
import styled from "styled-components"

const SkillListItem = styled.li`
  background-color: #eee;
  border-radius: 25px;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.5rem;
`

const Skill = ({ skill }) => <SkillListItem>{skill}</SkillListItem>

export default Skill
