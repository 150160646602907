import React from "react"
import styled from "styled-components"
import PersonalityListItem from "./PersonalityListItem"
// import BoxListItem from "./BoxListItem"

const PersonalityListUL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: 0;

  li {
    margin-right: 0.5rem;
  }

  li:last-child {
    margin-right: 0;
  }
`

const PersonalityList = ({ content }) => {
  const { listitems } = content
  return (
    <div style={{ marginTop: '2rem' }}>
      <div style={{ marginBottom: `.5rem` }}>
        <h3>{content.title}</h3>
      </div>
      <PersonalityListUL>
        {listitems.map((data, index) => {
          return (
            <PersonalityListItem
              key={`personality_list_item_${index}`}
              data={data}
            />
          )
        })}
      </PersonalityListUL>
    </div>
  )
}

export default PersonalityList
