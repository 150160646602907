import React from "react"
import ResumeSection from "./ResumeSection"
import Experience from "./Experience"
import Toggle from "./Toggle"
import ResumeSectionHeadingWithIcon from "./ResumeSectionHeadingWithIcon"

const FieldTesting = ({ content, fieldtestinglist }) => {
  const { frontmatter } = content.edges[0].node
  const fieldTestingList = fieldtestinglist.edges
  return (
    <ResumeSection>
      <ResumeSectionHeadingWithIcon content={frontmatter} />
      {fieldTestingList.map((data, index) => {
        const showMore = data.node.frontmatter.showMore
        return (
          !showMore && <Experience key={`experience_${index}`} content={data} />
        )
      })}
      <Toggle>
        {fieldTestingList.map((data, index) => {
          const showMore = data.node.frontmatter.showMore
          return (
            showMore && (
              <Experience
                key={`experience_show_more_${index}`}
                content={data}
              />
            )
          )
        })}
      </Toggle>
    </ResumeSection>
  )
}

export default FieldTesting
