import React from "react"

const PerformanceLegend = ({ content }) => {
  const { legendImage, legendImageAltText, legendLabels } = content
  return (
    <div style={{ marginBottom: `-2rem`, marginLeft: '13rem'}}>
      <ul
        style={{
          display: `flex`,
          fontSize: `0.857rem`,
          fontWeight: `bold`,
          justifyContent: `space-between`,
          listStyleType: `none`,
          marginBottom: '0.8rem'
        }}
      >
        {legendLabels.map((data, index) => {
          return <li key={`label_${index}`}>{data}</li>
        })}
      </ul>
      <div>
        <img src={legendImage} alt={legendImageAltText} />
      </div>
    </div>
  )
}

export default PerformanceLegend
